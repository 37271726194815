import styled from 'styled-components';
import { media } from '../../../../styles/mixins';

export const Wrapper = styled.div`
  margin-top: 76px;

    ${media.mobile`
      margin-top: 54px;
    `}
  
  h2 {
    color: #030D41;
    font-size: 40px;
    font-weight: 600;
    line-height: 54px;
    margin-bottom: 18px;

      ${media.mobile`
        font-size: 27px;
        font-weight: 500;
        line-height: 36px;
      `}
  }
  
  p {
    color: #6E707C;
    max-width: 800px;
    margin-bottom: 18px;
    font-size: 18px;
    font-weight: 400;
    line-height: 27px;
  }
`;

export const BeforeAfterBlock = styled.div`
    display: grid;
    grid-gap: 18px;
    grid-template-columns: repeat(2, 1fr);
    margin-bottom: 49px;

    ${media.mobile`
      grid-template-columns: repeat(1, 1fr);
      margin-bottom: 36px;
    `}
`;
