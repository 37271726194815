import { graphql } from 'gatsby';

import { PageWrapper } from 'components/Layout/PageWrapper';
import { MainContainer } from 'components/UI/Containers';
import { BeforeAfter } from '../ServicePage/components/Description';
import { RoundedContainer } from '../../styles/elements';
import { StyledStructured } from '../../components/StyledStructured';
import Image from '../../components/Image';
import { InformationWrapper, HeroWrapper } from './styles';

const AboutPageTemplate = ({ data, pageContext }) => {
  const {
    datoCmsSymmetryAboutPage: pageData,
  } = data;
  const { seo, heroText, information, mainPhoto } = pageData;
  const { seoTitle, seoDescription, seoImage } = seo || {};

  return (
    <PageWrapper
      pageData={pageContext}
      {...{ seoTitle, seoDescription, seoImage }}
    >
      <MainContainer>
        <HeroWrapper>
          <StyledStructured
            data={heroText}
          />
          <Image image={mainPhoto} alt="" />
        </HeroWrapper>
        <InformationWrapper>
          <StyledStructured
            data={information}
            renderBlock={({ record }) => {
              const { before, after } = record;
              // eslint-disable-next-line no-underscore-dangle
              switch (record.__typename) {
                case 'DatoCmsSBeforeAfter':
                  return <BeforeAfter {...{ before, after }} />;
                default:
                  return null;
              }
            }}
          />
        </InformationWrapper>
      </MainContainer>
    </PageWrapper>
  );
};

export default AboutPageTemplate;

export const query = graphql`
  query AboutPageQuery($locale: String!) {
    datoCmsSymmetryAboutPage(locale: { eq: $locale }) {
      locale
      heroText {
        value
      }
      mainPhoto {
        gatsbyImageData
        format
        url
      }
      information {
        value
        links
        blocks {
          __typename
          id: originalId
          before {
            gatsbyImageData
            url
            format
          }
          after {
            gatsbyImageData
            url
            format
          }
        }
      }
      seo {
        seoTitle: title
        seoDescription: description
        seoImage: image {
          seoImageUrl: url
        }
      }
    }
  }
`;
