import styled from 'styled-components';
import { RoundedContainer } from 'styles/elements';
import { media } from 'styles/mixins';

export const HeroWrapper = styled(RoundedContainer) `
    .gatsby-image-wrapper {
        border-radius: 10px;
    }
    
    h1 {
        color: #030D41;
        font-size: 54px;
        font-weight: 600;
        line-height: 54px;
        margin-bottom: 36px;
    }
    
    p {
        color: #6E707C;
        font-size: 27px;
        font-style: normal;
        font-weight: 600;
        line-height: 36px;
        margin-bottom: 1em;
    }
`;

export const InformationWrapper = styled.div`
    display: flex;
    flex-direction: column;
    max-width: 1000px;
    width: 100%;
    padding: 54px;

    ${media.tablet`
        padding: 27px;
      `}

    ${media.mobile`
        padding: 18px;
      `}
    
    .gatsby-image-wrapper {
        border-radius: 10px;
    }
    
    h3 {
        color: #030D41;
        font-size: 40px;
        font-weight: 600;
        line-height: 54px;
        margin-bottom: 18px;
    }
    
    p {
        color: #6E707C;
        margin-bottom: 1em;
        
        & + div {
            margin: 36px 0;
        }
    }
`;
