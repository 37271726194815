import { StyledStructured } from 'components/StyledStructured';
import Image from 'components/Image';
import { BeforeAfterBlock, Wrapper } from './styles';

const BeforeAfter = ({ before, after }) => (
  <BeforeAfterBlock>
    <Image image={before} />
    <Image image={after} />
  </BeforeAfterBlock>
);

const Description = ({ description }) => {
  return (
    <Wrapper>
      <StyledStructured
        data={description}
        renderBlock={({ record }) => {
          const { before, after } = record;
          // eslint-disable-next-line no-underscore-dangle
          switch (record.__typename) {
            case 'DatoCmsSBeforeAfter':
              return <BeforeAfter {...{ before, after }} />;
            default:
              return null;
          }
        }}
      />
    </Wrapper>
  );
};

export { Description, BeforeAfter };
